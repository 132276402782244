

.tgl {
    display: none;
  }
  .tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl + .tgl-btn {
    box-sizing: border-box;
  }
  .tgl::-moz-selection, .tgl:after::-moz-selection, .tgl:before::-moz-selection, .tgl *::-moz-selection, .tgl *:after::-moz-selection, .tgl *:before::-moz-selection, .tgl + .tgl-btn::-moz-selection {
    background: none;
  }
  .tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tgl-btn::selection {
    background: none;
  }
  .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
            margin: 0 auto;

  }
  .tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
  }
  .tgl + .tgl-btn:after {
    left: 0;
  }
  .tgl + .tgl-btn:before {
    display: none;
  }
  .tgl:checked + .tgl-btn:after {
    left: 50%;
  }
  
  .tgl-skewed + .tgl-btn {
    overflow: hidden;
    -webkit-transform: skew(-10deg);
            transform: skew(-10deg);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transition: all .2s ease;
    font-family: sans-serif;
    background: #888;
  }
  .tgl-skewed + .tgl-btn:after, .tgl-skewed + .tgl-btn:before {
    -webkit-transform: skew(25deg);
            transform: skew(25deg);
    display: inline-block;
    transition: all .2s ease;
    width: 100%;
    text-align: center;
    position: absolute;
    line-height: 2em;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  }
  .tgl-skewed + .tgl-btn:after {
    left: 100%;
    content: attr(data-tg-on);
  }
  .tgl-skewed + .tgl-btn:before {
    left: 0;
    content: attr(data-tg-off);
  }
  .tgl-skewed + .tgl-btn:active {
    background: #888;
  }
  .tgl-skewed + .tgl-btn:active:before {
    left: -10%;
  }
  .tgl-skewed:checked + .tgl-btn {
    background: #44393f;
  }
  .tgl-skewed:checked + .tgl-btn:before {
    left: -100%;
  }
  .tgl-skewed:checked + .tgl-btn:after {
    left: 0;
  }
  .tgl-skewed:checked + .tgl-btn:active:after {
    left: 10%;
  }
  
  /* =====================================================
     Some defaults across all toggle demos
     ===================================================== */
  .toggle {
    display: block;
    text-align: center;
   
    user-select: none;
  }
  
  .toggle--checkbox {
    display: none;
  }
  
  .toggle--btn {
    display: block;
    margin: 0 auto;
    font-size: 1.4em;
    transition: all 350ms ease-in;
  }
  .toggle--btn:hover {
    cursor: pointer;
  }
  
  .toggle--btn, .toggle--btn:before, .toggle--btn:after,
  .toggle--checkbox,
  .toggle--checkbox:before,
  .toggle--checkbox:after,
  .toggle--feature,
  .toggle--feature:before,
  .toggle--feature:after {
    transition: all 250ms ease-in;
  }
  .toggle--btn:before, .toggle--btn:after,
  .toggle--checkbox:before,
  .toggle--checkbox:after,
  .toggle--feature:before,
  .toggle--feature:after {
    content: '';
    display: block;
  }
  