.toggle-container {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}

.dialog-button {
  display: inline-block;
  width: 60px;
  height: 32px;
  border-radius: 16px;
  background-color: #e6e6e6;
  position: relative;
  margin-right: 10px;
  transition: all 0.3s;
}

.dialog-button-circle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #fff;
  transition: all 0.3s;
}

.dialog-button.active {
  background-color: #4cd964;
}

.dialog-button.active .dialog-button-circle {
  transform: translateX(28px);
}
