/* Estilo general para el select */
.select-tag {
    width: 100%; /* Ocupa todo el ancho disponible */
    padding: 8px 12px; /* Espaciado interno */
    font-size: 16px; /* Tamaño de la fuente */
    background-color: #fff; /* Fondo blanco */
    border: 1px solid #ccc; /* Borde gris */
    border-radius: 4px; /* Bordes redondeados */
    box-sizing: border-box; /* Asegura que el padding no afecte al tamaño total */
    transition: all 0.3s ease; /* Transición suave para el foco */
  }
  
  /* Estilo cuando el select está enfocado */
  .select-tag:focus {
    outline: none; /* Quitar el borde por defecto */
    border-color: #3f51b5; /* Cambia el borde al color primario de Material-UI */
    box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.2); /* Sombra de enfoque */
  }
  
  /* Estilo para las opciones dentro del select */
  .select-tag option {
    padding: 8px; /* Espaciado para las opciones */
    font-size: 16px; /* Tamaño de fuente */
  }
  
  /* Estilo cuando el select está deshabilitado */
  .select-tag:disabled {
    background-color: #f5f5f5; /* Fondo gris claro */
    border-color: #ddd; /* Borde gris claro */
    cursor: not-allowed; /* Cambia el cursor */
  }
  
  /* Estilo cuando el select tiene la propiedad multiple */
  .select-tag[multiple] {
    height: 300px; /* Ajuste automático de altura */
    min-height: 100px; /* Altura mínima */
  }
  