/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
.cd-timeline-block {
    opacity: 0;
    transform: translateY(100px);
    transition: all 0.5s ease-out;
  }
  
  .cd-timeline-block.visible {
    opacity: 1;
    transform: translateY(0);
  }


  
body {
	/* line-height: 1; */
  overflow: "auto";
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}



html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  *, *:after, *:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  body {
    font-size: 100%;
    font-family: "Droid Serif", serif;
    color: #7f8c97;
    background-color: #e9f0f5;
  }
  
  a {
    color: #acb7c0;
    text-decoration: none;
    font-family: "Open Sans", sans-serif;
  }
  
  img {
    max-width: 100%;
  }
  
  h1, h2 {
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
  }
  
  /* -------------------------------- 
  
  Modules - reusable parts of our design
  
  -------------------------------- */
  .cd-container {
    /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
    width: 90%;
    max-width: 1170px;
    margin: 0 auto;
  }
  .cd-container::after {
    /* clearfix */
    content: '';
    display: table;
    clear: both;
  }
  
  /* -------------------------------- 
  
  Main components 
  
  -------------------------------- */
  header {
    /* height: 200px; */
    /* line-height: 200px; */
    text-align: center;
    background: #303e49;
  }
  header h1 {
    color: white;
    font-size: 18px;
    font-size: 1.125rem;
  }


  
  
  @media only screen and (min-width: 1170px) {
    /* header { */
      /* height: 300px; */
      /* line-height: 300px; */
    /* } */
    header h1 {
      font-size: 24px;
      font-size: 1.5rem;
    }
  }
  
  #cd-timeline {
    position: relative;
    padding: 2em 0;
    margin-top: 2em;
    margin-bottom: 2em;
    width: 100%;
    /* background: #191515; */

  }
  #cd-timeline::before {
    /* this is the vertical line */
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: #37474f;
  }
  @media only screen and (min-width: 1170px) {
    #cd-timeline {
      margin-top: 3em;
      margin-bottom: 3em;
    }
    #cd-timeline::before {
      left: 50%;
      margin-left: -2px;
    }
  }
  
  .cd-timeline-block {
    position: relative;
    margin: 2em 0;
  }
  .cd-timeline-block:after {
    content: "";
    display: table;
    clear: both;
  }
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }


  


  @media only screen and (min-width: 1170px) {
    .cd-timeline-block {
      margin: 4em 0;
    }
    .cd-timeline-block:first-child {
      margin-top: 0;
    }
    .cd-timeline-block:last-child {
      margin-bottom: 0;
    }
  }
  
  .cd-timeline-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px #121212, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .cd-timeline-img img {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px;
  }
  .cd-timeline-img.cd-picture {
    background: #00695c;
  }
  .cd-timeline-img.cd-movie {
    background: #b71c1c;
  }
  .cd-timeline-img.cd-location {
    background: #f9a825;
  }
  @media only screen and (min-width: 1170px) {
    .cd-timeline-img {
      width: 60px;
      height: 60px;
      left: 50%;
      margin-left: -30px;
      /* Force Hardware Acceleration in WebKit */
      transform: translateZ(0);
      backface-visibility: hidden;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .cssanimations .cd-timeline-img.is-hidden {
      visibility: hidden;
    }
    .cssanimations .cd-timeline-img.bounce-in {
      visibility: visible;
      -webkit-animation: cd-bounce-1 0.6s;
      -moz-animation: cd-bounce-1 0.6s;
      animation: cd-bounce-1 0.6s;
    }
  }
  
  @-webkit-keyframes cd-bounce-1 {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
    }
  
    60% {
      opacity: 1;
      -webkit-transform: scale(1.2);
    }
  
    100% {
      -webkit-transform: scale(1);
    }
  }
  @-moz-keyframes cd-bounce-1 {
    0% {
      opacity: 0;
      -moz-transform: scale(0.5);
    }
  
    60% {
      opacity: 1;
      -moz-transform: scale(1.2);
    }
  
    100% {
      -moz-transform: scale(1);
    }
  }
  @keyframes cd-bounce-1 {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
      -moz-transform: scale(0.5);
      -ms-transform: scale(0.5);
      -o-transform: scale(0.5);
      transform: scale(0.5);
    }
  
    60% {
      opacity: 1;
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
    }
  
    100% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  
  .cd-timeline-content {
    position: relative;
    margin-left: 60px;
    background: 263238;
    border-radius: 0.25em;
    padding: 1em;
    box-shadow: 0 3px 0 #37474f;
  }
  .cd-timeline-content:after {
    content: "";
    display: table;
    clear: both;
  }

  
  
/* Dark Theme & IoT Fonts */

/* Cambiar el fondo de la página y el color del texto */
body {
  font-family: 'Roboto Mono', monospace; /* Fuente con temática tecnológica */
  color: #d0d7df;
  background-color: #121212;
}

/* Cambiar el color de los enlaces */
a {
  color: #80cbc4; /* Color cian típico de interfaces tecnológicas */
}

/* Cambiar el fondo y color del encabezado */
header {
  background: #1f2933;
}
header h1 {
  color: #cfd8dc; /* Texto de encabezado más claro */
}

/* Línea del timeline */
#cd-timeline::before {
  background: #37474f; /* Línea vertical en color gris oscuro */
}

/* Fondos y sombras de los bloques de contenido */
.cd-timeline-content {
  background: #263238; /* Fondo oscuro */
  box-shadow: 0 3px 0 #37474f; /* Sombra más oscura */
}
.cd-timeline-content h2 {
  color: #80deea; /* Título de sección en cian claro */
}
.cd-timeline-content p, .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
    font-size: 13px;
    font-size: 0.8125rem;
  }
  .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
    display: inline-block;
  }
  .cd-timeline-content p {
    margin: 1em 0;
    line-height: 1.6;
    color: #b0bec5; /* Texto del contenido en gris claro */

  }
  .cd-timeline-content .cd-read-more {
    float: right;
    padding: .8em 1em;
    background: #00796b;
    color: white;
    border-radius: 0.25em;
  }
  .no-touch .cd-timeline-content .cd-read-more:hover {
    background-color: #004d40;
  }
  .cd-timeline-content .cd-date {
    float: left;
    padding: .8em 0;
    opacity: .7;
    color: #607d8b; /* Fecha en gris azulado */
  }
  .cd-timeline-content::before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #462626;
  }
  @media only screen and (min-width: 768px) {
    .cd-timeline-content h2 {
      font-size: 20px;
      font-size: 1.25rem;
    }
    .cd-timeline-content p {
      font-size: 16px;
      font-size: 1rem;
    }
    .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
      font-size: 14px;
      font-size: 0.875rem;
    }
  }
  @media only screen and (min-width: 1170px) {
    .cd-timeline-content {
      margin-left: 0;
      padding: 1.6em;
      width: 45%;
    }
    .cd-timeline-content::before {
      top: 24px;
      left: 100%;
      border-color: transparent;
      border-left-color: #462626;
    }
    .cd-timeline-content .cd-read-more {
      float: left;
    }
    .cd-timeline-content .cd-date {
      position: absolute;
      width: 100%;
      left: 122%;
      top: 6px;
      font-size: 16px;
      font-size: 1rem;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content {
      float: right;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
      top: 24px;
      left: auto;
      right: 100%;
      border-color: transparent;
      border-right-color: #462626;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
      float: right;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
      left: auto;
      right: 122%;
      text-align: right;
    }
    .cssanimations .cd-timeline-content.is-hidden {
      visibility: hidden;
    }
    .cssanimations .cd-timeline-content.bounce-in {
      visibility: visible;
      -webkit-animation: cd-bounce-2 0.6s;
      -moz-animation: cd-bounce-2 0.6s;
      animation: cd-bounce-2 0.6s;
    }
  }
  
  @media only screen and (min-width: 1170px) {
    /* inverse bounce effect on even content blocks */
    .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
      -webkit-animation: cd-bounce-2-inverse 0.6s;
      -moz-animation: cd-bounce-2-inverse 0.6s;
      animation: cd-bounce-2-inverse 0.6s;
    }
  }
  @-webkit-keyframes cd-bounce-2 {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-100px);
    }
  
    60% {
      opacity: 1;
      -webkit-transform: translateX(20px);
    }
  
    100% {
      -webkit-transform: translateX(0);
    }
  }
  @-moz-keyframes cd-bounce-2 {
    0% {
      opacity: 0;
      -moz-transform: translateX(-100px);
    }
  
    60% {
      opacity: 1;
      -moz-transform: translateX(20px);
    }
  
    100% {
      -moz-transform: translateX(0);
    }
  }
  @keyframes cd-bounce-2 {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-100px);
      -moz-transform: translateX(-100px);
      -ms-transform: translateX(-100px);
      -o-transform: translateX(-100px);
      transform: translateX(-100px);
    }
  
    60% {
      opacity: 1;
      -webkit-transform: translateX(20px);
      -moz-transform: translateX(20px);
      -ms-transform: translateX(20px);
      -o-transform: translateX(20px);
      transform: translateX(20px);
    }
  
    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @-webkit-keyframes cd-bounce-2-inverse {
    0% {
      opacity: 0;
      -webkit-transform: translateX(100px);
    }
  
    60% {
      opacity: 1;
      -webkit-transform: translateX(-20px);
    }
  
    100% {
      -webkit-transform: translateX(0);
    }
  }
  @-moz-keyframes cd-bounce-2-inverse {
    0% {
      opacity: 0;
      -moz-transform: translateX(100px);
    }
  
    60% {
      opacity: 1;
      -moz-transform: translateX(-20px);
    }
  
    100% {
      -moz-transform: translateX(0);
    }
  }
  @keyframes cd-bounce-2-inverse {
    0% {
      opacity: 0;
      -webkit-transform: translateX(100px);
      -moz-transform: translateX(100px);
      -ms-transform: translateX(100px);
      -o-transform: translateX(100px);
      transform: translateX(100px);
    }
  
    60% {
      opacity: 1;
      -webkit-transform: translateX(-20px);
      -moz-transform: translateX(-20px);
      -ms-transform: translateX(-20px);
      -o-transform: translateX(-20px);
      transform: translateX(-20px);
    }
  
    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }
  