body {
    /* background: #020438; */
    font: 14px/1 'Open Sans', helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
  }
  .unitsB{
    position: relative;
    text-align: center;
    /* left: 1%; */
    top: 79%;
    z-index: 10;
    color:#ecfbd6;
    font-size: large;
  }
  .water-jar {
    height: 250px;
    width: 250px;
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: linear-gradient(45deg, #73365a, transparent);
    border-radius: 100%;
    overflow: hidden;
  }
  
  .water-jar .water-filling {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: #fff;
    font-size: 64px;
  }
  
  .water-jar .water {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
    background: #4D6DE3;
  }
  
  .water-jar .water_wave {
    width: 200%;
    position: absolute;
    /* bottom: 100%; */
    bottom: 100%;
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
  
  .water-jar .water_wave_back {
    right: 0;
    fill: #C7EEFF;
    -webkit-animation: wave-back .5s   infinite linear;
            animation: wave-back .5s   infinite linear; 
  }
  .water-jar .water_wave_back use {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-animation: wave-height 2s linear 1 forwards;
            animation: wave-height 2s linear 1 forwards;
  }
  .cTemback{
    fill:#7af80a;
  }
  .cTemfront{
    fill:#7af80a;
  }
  .cLevback{
    fill:#4D6DE3;
  }
  .cLevfront{
    fill:#4D6DE3;
  }
  .cHumeback{
    fill: rgb(77, 153, 227);
  }
  .cHumefront{
    fill:rgb(77, 153, 227);
  }

  .water-jar .water_wave_front {
    left: 0;
    /* fill: #4D6DE3; */
    margin-bottom: -1px;
    -webkit-animation: wave-front 0.17s infinite linear;
            animation: wave-front 0.17s infinite linear;
  }
  
  .water-jar .water_wave_front use {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;  
    -webkit-animation: wave-height-initial  2s linear 1 forwards;
    animation: wave-height-initial  2s linear 1 forwards;
  }
  .water-jar:hover .water_wave_front use {
    -webkit-animation: wave-height 2s ease-out 1 forwards;
            animation: wave-height 2s ease-out 1 forwards;
  }
  
  @-webkit-keyframes wave-front {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
    }
  }
  
  @keyframes wave-front {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
    }
  }
  

  
  @-webkit-keyframes wave-height {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
    100% {
      -webkit-transform: scaleY(0.1);
              transform: scaleY(0.1);
    }
  }
  
  @keyframes wave-height {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
    100% {
      -webkit-transform: scaleY(0.1);
              transform: scaleY(0.1);
    }
  }
  
  
  @-webkit-keyframes wave-height-initial {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
    100% {
      -webkit-transform: scaleY(0.1);
              transform: scaleY(0.1);
    }
  }
  
  @keyframes wave-height-initial {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
    100% {
      -webkit-transform: scaleY(0.1);
              transform: scaleY(0.1);
    }
  }


  @-webkit-keyframes wave-back {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(50%);
              transform: translateX(50%);
    }
  }
  
  @keyframes wave-back {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(50%);
              transform: translateX(50%);
    }
  }
  