.container {
    perspective: 1000px; /* Define la profundidad del efecto 3D */
    display: inline-block;
  }
  
  .imagen-3d {
    width: 300px; /* Ajusta el tamaño de la imagen */
    transition: transform 0.5s ease; /* Suaviza la animación */
    transform-style: preserve-3d; /* Mantiene el 3D en los hijos */
  }
  
  .container:hover .imagen-3d {
    transform: rotateY(20deg) rotateX(10deg); /* Gira la imagen en 3D */
  }
  