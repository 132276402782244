.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.respuestaBox{
  word-wrap: break-word;
}
.formis{
  display: flex;

}
.select-parent{
  
  height:250px;
  display:inline-flex;
  margin-left: 10%;
  margin-top: 69px;
  }
  .SelctDiv{
      width:20%;
      height:100%;
  }
  
  .select-tag {
      width: 100%;
      height: 100%;
  }
  .select-tag option{
  margin:5px;
  }
  .select-btn-div {

    
      flex-direction: column;
      justify-content: center;
      padding: 20px;
      width: 30%;
      text-align: left;
  }
  .select-btn-div  button{
      margin-bottom:10px;
  }
  

  .elemColRight{
    margin-top: 22px;
    margin-bottom: 22px;
  }